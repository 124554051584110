.dashboard-button {
    position: relative; /* Allows for absolute positioning of the text */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 225px; /* Adjust the height as needed */
    border-radius: 50px;
    max-width: 500px;
    background-size: cover;
    background-position: center;
    text-align: center;
    transition: transform 0.3s ease;
    border: 1px solid navy;
    margin: 0 auto;
  }
  
  .dashboard-button:hover {
    transform: scale(1.05);
  }
  
  .button-content {
    position: absolute; /* Position the content relative to the button */
    bottom: -10%; /* Adjusts the distance from the bottom */
    left: 50%;
    min-width: 50%;
    transform: translateX(-50%); /* Centers the text horizontally */
    background-color: rgba(1, 1, 122, 0.8); /* Semi-transparent background */
    color: white;
    padding: 5px 10px;
    border-radius: 15px;
    border: 1px solid lightblue;
  }
  
  .dashboard-button p {
    margin: 0;
    font-size: 1.2rem;
  }
  