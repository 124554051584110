.app-main {
    background-color: #fafafa;
}

.app-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .app-header, .app-footer {
    background-color: lightblue;
    color: navy;
    text-align: center;
    padding: 0;
    border-top: 1px solid navy;
    border-bottom: 1px solid navy;
  }

  .app-header h1, .app-header p {
    text-shadow: 2px 2px 4px rgba(255, 255, 255, 1); /* Text shadow for more contrast */
    font-weight: bold; /* Make the text bolder */
}
  
  .app-header {
    font-size: 2rem;
    background-size: cover;
  }

  .app-header h1 {
    margin-bottom: 0;
    margin-top: 5rem;
  }
  
  .app-header p {
    font-size: 1.5rem;
    margin-bottom: 5rem;
  }

  .app-main {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 4em;
    width: 95%;
    max-width: 1200px;
    margin: 2em auto;
  }

  /* 3rd button-grid span 2 */
  /* .button-grid .stretch {
    grid-column: span 2;
  } */
  
  .app-footer {
    font-size: 1rem;
    font-weight: bolder;
  }
  
/* Cell phone styling */

@media (max-width: 800px) {
    .app-header {
      font-size: 1rem;
      padding-top: 1em;
    }
  
    .app-header p {
      font-size: 1rem;
    }
  
    .app-footer {
      font-size: 0.8rem;
    }
    .button-grid {
      grid-template-columns: 1fr;
      gap: 2em;
    }
    /* .button-grid .stretch {
      grid-column: span 1;
    } */
  }